<template>
  <div class="FdtbFormWrap">
    <div class="FdtbFormTop">
      <div class="FdtbFormBox1">
        <div class="FdtbFormTitle">
          <span></span>
          <b>基本信息</b>
        </div>
        <div class="FdtbFormContent">
          <div class="wire">
            <p>
              <span class="star">*</span>
              <span class="title">姓名</span>
            </p>
            <van-field v-model="Form.Name" placeholder="请输入姓名" readonly />
          </div>
          <div class="wire">
            <p>
              <span class="star">*</span>
              <span class="title">身份证号</span>
            </p>
            <van-field
              v-model="Form.Ider"
              placeholder="请输入身份证号"
              readonly
            />
          </div>
          <div class="wire">
            <p>
              <span class="star">*</span>
              <span class="title">电话</span>
            </p>
            <van-field
              v-model="Form.Iphone"
              type="tel"
              :maxlength="11"
              placeholder="请输入电话"
            />
          </div>
          <div class="wire">
            <p>
              <span class="star">*</span>
              <span class="title">所住区域</span>
            </p>
            <van-field
              v-model="Form.County"
              is-link
              readonly
              placeholder="请选择所在地区"
              @click="CountyShow = true"
            />
            <van-popup v-model="CountyShow" round position="bottom">
              <van-cascader
                v-model="Form.CountyValue"
                title="请选择所在地区"
                :options="CountyOptions"
                @close="CountyShow = false"
                @finish="onCountyFinish"
                @change="onCountyChange"
              />
            </van-popup>
          </div>
          <div class="wire">
            <p>
              <span class="star">*</span>
              <span class="title">详细地址</span>
            </p>
            <van-field v-model="Form.Address" placeholder="请输入详细地址" />
          </div>
        </div>
      </div>
      <div class="FdtbFormBox2">
        <div class="FdtbFormTitle">
          <span></span>
          <b>其他信息</b>
        </div>
        <div class="FdtbFormContent">
          <div class="wire">
            <p>
              <span class="star">*</span>
              <span class="title">来源地</span>
            </p>
            <van-field
              v-model="Form.Source"
              is-link
              readonly
              placeholder="请选择所在地区"
              @click="SourceShow = true"
            />
            <van-popup v-model="SourceShow" round position="bottom">
              <van-cascader
                v-model="Form.SourceValue"
                title="请选择所在地区"
                :options="SourceOptions"
                @close="SourceShow = false"
                @finish="onSourceFinish"
                @change="onSourceChange"
              />
            </van-popup>
          </div>
          <div class="wire">
            <p>
              <span class="star">*</span>
              <span class="title">详细地址</span>
            </p>
            <van-field v-model="Form.laddress" placeholder="请输入详细地址" />
          </div>
          <div class="wire">
            <p>
              <span class="star">*</span>
              <span class="title">抵德日期</span>
            </p>
            <van-field
              readonly
              clickable
              name="datetimePicker"
              :value="Form.Time"
              placeholder="点击选择时间"
              @click="showPicker = true"
            />
            <van-popup v-model="showPicker" position="bottom">
              <van-datetime-picker
                @confirm="onConfirm"
                @cancel="showPicker = false"
                v-model="currentDate"
                type="date"
                title="选择年月日"
                :min-date="minDate"
                :max-date="maxDate"
              />
            </van-popup>
          </div>
        </div>
      </div>
      <div class="FdtbFormBox3">
        <div class="FdtbFormBoxName">
          <span class="star">*</span>
          <span class="FdtbFormBoxTitle">是否中高风险所在县市区：</span>
        </div>
        <van-radio-group v-model="Form.fxradio" direction="horizontal">
          <van-radio :name="1">是</van-radio>
          <van-radio :name="2">否</van-radio>
        </van-radio-group>
      </div>
      <div class="FdtbFormBox3">
        <div class="FdtbFormBoxName">
          <span class="star">*</span>
          <span class="FdtbFormBoxTitle">是否有48小时内核酸阴性证明：</span>
        </div>
        <van-radio-group v-model="Form.hsradio" direction="horizontal">
          <van-radio :name="1">是</van-radio>
          <van-radio :name="2">否</van-radio>
        </van-radio-group>
      </div>
      <div class="FdtbFormBox4" v-if="Form.hsradio == '1'">
        <div class="FdtbFormHsImg">
          <span>上传48小时内核酸阴性证明</span>
          <span>（点击上传图片，限一张）</span>
        </div>
        <van-uploader
          v-model="fileList"
          multiple
          :max-count="1"
          :after-read="ImgClick"
          @delete="deleted"
        />
      </div>
      <div class="FdtbFormBox5">
        <div class="FdtbFormBox5Content">
          <div class="FdtbFormBoxName">
            <div class="FdtbFormBoxNameOne">
              <span class="star">*</span>
              <span class="FdtbFormBoxTitle">是否已申领山东健康码：</span>
            </div>
            <span class="hint">
              （可在微信小程序“山东电子健康通行卡”内申请）
            </span>
          </div>
          <van-radio-group v-model="Form.sdjkm" direction="horizontal">
            <van-radio :name="1">是</van-radio>
            <van-radio :name="2">否</van-radio>
          </van-radio-group>
        </div>
      </div>
    </div>
    <div class="FdtbFormBottom">
      <div class="FdtbFormUrl">
        <div>
          <span>注：点击查询低中高风险地区：</span>
        </div>
        <a href="http://www.gov.cn/zhuanti/2021yqfkgdzc/mobile.htm">
          http://www.gov.cn/zhuanti/2021yqfkgdzc/mobile.htm
        </a>
      </div>
      <van-button type="info" class="FdtbFormBtn" @click="() => FdtbFormBtn()">
        提交
      </van-button>
    </div>
  </div>
</template>
<script>
  import { mapState, mapMutations } from 'vuex';
  import {
    areadictlist,
    provinces,
    cities,
    area,
    savepersonneinfo,
    personneinfo,
    addproblemimgbypath,
    addproblemimg,
  } from '@/api/Content';
  const BASE_OSS_URL =
    process.env.NODE_ENV === 'production'
      ? window.location.origin + '/oss/'
      : 'https://yqtb.yode.cn/oss/';
  import storage from 'store';
  import { Toast } from 'vant';

  export default {
    data() {
      return {
        BASE_OSS_URL,
        Form: {
          Name: '',
          Ider: '',
          Iphone: '', //联系方式
          Address: '', //所住详细地址
          fxradio: '', //中高风险状态（1：是，2否）
          hsradio: '', //48核酸图片（1：是，2否）
          sdjkm: '', //申领山东健康码
          Time: '', //抵德时间
          laddress: '', //来源地址
          County: '',
          CountyValue: '',
          Source: '',
          SourceValue: '',
          arealevel: '', //区级来源Name
          citylevel: '', //市级来源Name
          provinceevel: '', //省级来源Name
          county: '', //所在区县市Name
          community: '', //所在街道Name
          street: '', //所在社区Name
          arealevelid: '', //区级来源id
          citylevelid: '', //市级来源id
          provinceevelid: '', //省级来源id
          countyid: '', //所在区县市id
          communityid: '', //所在街道id
          streetid: '', //所在社区id
        },
        aishandongPic: '', //核酸照片
        CountyShow: false,
        SourceShow: false,
        showPicker: false,
        // 选项列表，children 代表子选项，支持多级嵌套
        CountyOptions: [],
        SourceOptions: [],
        HsImg: require('@/assets/HsImg.svg'),
        minDate: new Date(2020, 0, 1),
        maxDate: new Date(2025, 10, 1),
        currentDate: new Date(),
        CountyOptionsList: [],
        fileList: [],
        ZMImg: '',
      };
    },
    computed: {
      ...mapState('user', ['userInfo']),
    },
    mounted() {
      // this.Form.Name = this.userInfo.username.replace(
      //   /(?<=[\u4e00-\u9fa5]).*(?=[\u4e00-\u9fa5])/,
      //   '*'
      // );
      // this.Form.Ider = this.userInfo.idcard.replace(
      //   /(\d{2})\d*(\d{2})/,
      //   '$1**************$2'
      // );
      this.Form.Name = storage.get('UserName');
      this.Form.Ider = storage.get('Idcard');
      // this.Form.Iphone = storage.get('mobile');
      this.getPersonneinfo();
      this.timeFormat(new Date());
      this.getAreadictlist();
      this.getProvinces();
    },
    methods: {
      ImgClick(e) {
        const fd = new FormData();
        fd.append('file', e.file);
        addproblemimg(fd).then((data) => {
          this.ZMImg = data.result;
        });
      },
      onConfirm(value) {
        this.Form.Time = this.timeFormat(value);
        this.showPicker = false;
      },
      timeFormat(time) {
        let year = time.getFullYear();
        let month = time.getMonth() + 1;
        let day = time.getDate();
        return year + '-' + month + '-' + day;
      },
      getAreadictlist() {
        this.Form.countyid = '3714000000';
        areadictlist(this.Form.countyid).then((data) => {
          const list = [];
          data.result.map((item) => {
            list.push({
              text: item.dictname,
              value: item.dictid,
              children: [],
            });
          });
          this.CountyOptions = list;
        });
      },
      onCountyChange({ value, selectedOptions, tabIndex }) {
        if (tabIndex == 0) {
          this.Form.county = selectedOptions[0].text;
          this.Form.countyid = selectedOptions[0].value;
          areadictlist(selectedOptions[0].value).then((data) => {
            const list = [];
            data.result.map((item) => {
              list.push({
                text: item.dictname,
                value: item.dictid,
                children: [],
              });
            });
            selectedOptions[0].children = list;
          });
        } else if (tabIndex == 1) {
          this.Form.street = selectedOptions[1].text;
          this.Form.streetid = selectedOptions[1].value;
          areadictlist(selectedOptions[1].value).then((data) => {
            const list = [];
            data.result.map((item) => {
              list.push({
                text: item.dictname,
                value: item.dictid,
              });
            });
            selectedOptions[1].children = list;
          });
        } else if (tabIndex == 2) {
          this.Form.community = selectedOptions[2].text;
          this.Form.communityid = selectedOptions[2].value;
        }
      },
      onCountyFinish({ selectedOptions }) {
        this.CountyShow = false;
        this.Form.County = selectedOptions
          .map((option) => option.text)
          .join('/');
      },
      getProvinces() {
        provinces().then((data) => {
          const list = [];
          data.result.map((item) => {
            list.push({
              text: item.dictname,
              value: item.dictid,
              children: [],
            });
          });
          this.SourceOptions = list;
        });
      },
      onSourceChange({ value, selectedOptions, tabIndex }) {
        if (tabIndex == 0) {
          this.Form.provinceevel = selectedOptions[0].text;
          this.Form.provinceevelid = selectedOptions[0].value;
          cities(selectedOptions[0].value).then((data) => {
            const list = [];
            data.result.map((item) => {
              list.push({
                text: item.dictname,
                value: item.dictid,
                children: [],
              });
            });
            selectedOptions[0].children = list;
          });
        } else if (tabIndex == 1) {
          this.Form.citylevel = selectedOptions[1].text;
          this.Form.citylevelid = selectedOptions[1].value;
          area(selectedOptions[1].value).then((data) => {
            const list = [];
            data.result.map((item) => {
              list.push({
                text: item.dictname,
                value: item.dictid,
              });
            });
            selectedOptions[1].children = list;
          });
        } else if (tabIndex == 2) {
          this.Form.arealevel = selectedOptions[2].text;
          this.Form.arealevelid = selectedOptions[2].value;
        }
      },
      onSourceFinish({ selectedOptions }) {
        this.SourceShow = false;
        this.Form.Source = selectedOptions
          .map((option) => option.text)
          .join('/');
      },
      deleted() {
        this.fileList = [];
        this.ZMImg = '';
      },
      FdtbFormBtn() {
        let jy =
          /^1(3[0-9]|4[01456879]|5[0-35-9]|6[2567]|7[0-8]|8[0-9]|9[0-35-9])\d{8}$/;
        if (jy.test(this.Form.Iphone) == false) {
          return Toast('请填写正确的电话');
        }
        if (this.Form.Iphone == '') {
          return Toast('请填写电话');
        }
        if (this.Form.County == '') {
          return Toast('请选择所住区域');
        }
        if (this.Form.Address == '') {
          return Toast('请填写详细地址');
        }
        if (this.Form.Source == '') {
          return Toast('请选择来源地');
        }
        if (this.Form.laddress == '') {
          return Toast('请填写来源地详细地址');
        }
        if (this.Form.Time == '') {
          return Toast('请选择抵德日期');
        }
        if (this.Form.fxradio == '') {
          return Toast('请选择是否中高风险所在区县');
        }
        if (this.Form.hsradio == '') {
          return Toast('请选择是否有48小时核酸证明');
        }
        if (this.Form.sdjkm == '') {
          return Toast('请选择是否已经申领山东健康码');
        }
        savepersonneinfo({
          apply: this.Form.sdjkm,
          arealevel: this.Form.arealevel,
          arrivaltime: this.Form.Time,
          citylevel: this.Form.citylevel,
          community: this.Form.community,
          contact: this.Form.Iphone,
          county: this.Form.county,
          laddress: this.Form.laddress,
          nucleicacid: this.Form.hsradio,
          photourl: this.Form.hsradio == 1 ? this.ZMImg : '',
          provinceevel: this.Form.provinceevel,
          riskstate: this.Form.fxradio,
          street: this.Form.street,
          xaddress: this.Form.Address,
          arealevelid: this.Form.arealevelid,
          citylevelid: this.Form.citylevelid,
          provinceevelid: this.Form.provinceevelid,
          countyid: this.Form.countyid,
          communityid: this.Form.communityid,
          streetid: this.Form.streetid,
        }).then((data) => {
          Toast('提交成功!');
          window.history.go(-1);
        });
      },
      getPersonneinfo() {
        personneinfo().then((data) => {
          this.Form.sdjkm = data.result.apply;
          this.Form.arealevel = data.result.arealevel;
          this.Form.Time = data.result.arrivaltime.substring(0, 10);
          this.Form.citylevel = data.result.citylevel;
          this.Form.community = data.result.community;
          this.Form.Iphone = data.result.contact;
          this.Form.county = data.result.county;
          this.Form.laddress = data.result.laddress;
          this.Form.hsradio = data.result.nucleicacid;
          !!data.result.photourl &&
            this.fileList.push({ url: BASE_OSS_URL + data.result.photourl });
          this.ZMImg = data.result.photourl;
          this.Form.provinceevel = data.result.provinceevel;
          this.Form.fxradio = data.result.riskstate;
          this.Form.street = data.result.street;
          this.Form.Address = data.result.xaddress;
          this.Form.citylevelid = data.result.citylevelid;
          this.Form.arealevelid = data.result.arealevelid;
          this.Form.provinceevelid = data.result.provinceevelid;
          this.Form.countyid = data.result.countyid;
          this.Form.communityid = data.result.communityid;
          this.Form.streetid = data.result.streetid;
          this.Form.CountyValue = data.result.communityid;
          this.Form.SourceValue = data.result.arealevelid;
          if (data.result.county == null) {
            this.Form.County = '';
          } else {
            this.Form.County =
              data.result.county +
              '/' +
              data.result.street +
              '/' +
              data.result.community;
          }
          if (data.result.provinceevel == null) {
            this.Form.Source = '';
          } else {
            this.Form.Source =
              data.result.provinceevel +
              '/' +
              data.result.citylevel +
              '/' +
              data.result.arealevel;
          }
          areadictlist(this.Form.countyid).then((data) => {
            for (let index = 0; index < this.CountyOptions.length; index++) {
              const element = this.CountyOptions[index].value;
              if (element == this.Form.countyid) {
                const b = this.CountyOptions[index].children;
                data.result.map((a) => {
                  b.push({
                    text: a.dictname,
                    value: a.dictid,
                    children: [],
                  });
                });
                areadictlist(this.Form.streetid).then((y) => {
                  for (let index = 0; index < b.length; index++) {
                    const c = b[index].value;
                    if (c == this.Form.streetid) {
                      const a = b[index].children;
                      y.result.map((p) => {
                        a.push({
                          text: p.dictname,
                          value: p.dictid,
                        });
                      });
                    }
                  }
                });
              }
            }
          });
          cities(this.Form.provinceevelid).then((data) => {
            for (let index = 0; index < this.SourceOptions.length; index++) {
              const element = this.SourceOptions[index].value;
              if (element == this.Form.provinceevelid) {
                let b = this.SourceOptions[index].children;
                data.result.map((m) => {
                  b.push({
                    text: m.dictname,
                    value: m.dictid,
                    children: [],
                  });
                });
                area(this.Form.citylevelid).then((data) => {
                  for (let index = 0; index < b.length; index++) {
                    const element = b[index].value;
                    if (element == this.Form.citylevelid) {
                      let c = b[index].children;
                      data.result.map((o) => {
                        c.push({
                          text: o.dictname,
                          value: o.dictid,
                        });
                      });
                    }
                  }
                });
              }
            }
          });
        });
      },
    },
  };
</script>
<style lang="less" scoped>
  .FdtbFormWrap {
    width: 100%;
    height: auto;
    background: #fafcfd;
  }
  .FdtbFormTop {
    width: 100%;
    height: 83vh;
    overflow: auto;
  }
  .FdtbFormBottom {
    width: 100%;
    height: 13vh;
  }
  .FdtbFormUrl {
    width: 94%;
    height: 7vh;
    background: #f2f2f2;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 0 0 6%;

    div span:nth-of-type(1) {
      font-family: 'PingFang SC Medium';
      font-weight: bold;
      font-size: 0.6rem;
      text-align: left;
      color: #1c1c1c;
    }
    a {
      text-decoration: underline;
      font-family: 'PingFang SC Medium';
      font-size: 0.6rem;
    }
  }
  .star {
    color: #ff0000;
  }
  .FdtbFormBtn {
    width: 96%;
    margin: 0 0 0 2%;
    border-radius: 5px;
  }
  .FdtbFormBox1 {
    width: 100%;
    height: 30.5rem;
    border-radius: 6px;
    background: #fff;
    margin: 1rem 0 0.6rem 0;
  }
  .FdtbFormBox2 {
    width: 100%;
    height: 20.5rem;
    border-radius: 6px;
    background: #fff;
    margin: 0 0 0.6rem 0;
  }
  .FdtbFormBox3 {
    width: 100%;
    height: 3.5rem;
    display: flex;
    align-items: center;
    margin: 0 0 0.6rem 0;
    background: #fff;
    .FdtbFormBoxName {
      width: 15rem;
      margin: 0 0 0 7%;
      .FdtbFormBoxTitle {
        font-family: 'PingFang SC Medium';
        font-weight: bold;
        font-size: 0.8rem;
        text-align: left;
        color: #1c1c1c;
      }
    }
  }
  .FdtbFormBox4 {
    width: 100%;
    // height: 3.5rem;
    display: flex;
    justify-content: space-between;
    padding: 1rem 0;
    margin: 0 0 0.6rem 0;
    .FdtbFormHsImg {
      display: flex;
      flex-direction: column;
      margin: 0 0 0 7%;
      justify-content: center;
      span:nth-of-type(1) {
        font-family: 'PingFang SC Medium';
        font-weight: bold;
        font-size: 0.8rem;
        text-align: left;
        color: #1c1c1c;
        margin: 0 0 0.5rem 0;
      }
      span:nth-of-type(2) {
        font-family: 'PingFang SC';
        font-weight: 400;
        font-size: 0.6rem;
        text-align: left;
        color: #a3a3a3;
      }
    }
  }
  .FdtbFormBox5 {
    width: 100%;
    height: 3.5rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background: #fff;
    .FdtbFormBox5Content {
      display: flex;
      align-items: center;
      .FdtbFormBoxName {
        width: 15rem;
        margin: 0 0 0 7%;
        .FdtbFormBoxTitle {
          font-family: 'PingFang SC Medium';
          font-weight: bold;
          font-size: 0.8rem;
          text-align: left;
          color: #1c1c1c;
        }
      }
    }
    .hint {
      font-family: 'PingFang SC';
      font-weight: 400;
      font-size: 0.6rem;
      text-align: left;
      color: #a3a3a3;
    }
  }
  .FdtbFormTitle {
    display: flex;
    align-items: center;
    margin: 1rem 0 2rem 1rem;
    span {
      display: inline-block;
      width: 0.4rem;
      height: 0.8rem;
      border-radius: 3px;
      background: #0d4eed;
      margin: 0 0.4rem 0 0;
    }
  }
  .FdtbFormContent {
    .wire {
      background: transparent;
      border-bottom: 1px solid rgba(112, 112, 112, 0.1);
      width: 90%;
      margin: 0 0 6% 5%;
      .title {
        font-family: 'PingFang SC Medium';
        font-weight: bold;
        font-size: 1rem;
        text-align: left;
        color: #1c1c1c;
        margin: 0 0 0 0.5rem;
      }
    }
  }
  .HsImg {
    width: 5rem;
    height: 5rem;
    margin: 0 2.7rem 0 0;
  }
</style>
<style lang="less" scoped>
  ::v-deep {
    .van-cell {
      padding-left: 0.5rem;
    }
    .van-radio__icon {
      font-size: 1rem;
    }
    .van-radio__label {
      font-size: 0.9rem;
    }
    .van-uploader {
      margin: 0 2.7rem 0 0;
    }
  }
</style>
